import { TrackId } from '@scenes/Onboarding/types';

export const trackOneClickPayment = (
    paymentMethod: string,
    trackId?: TrackId,
) => {
    window?.analytics?.track('Track One Click Payment', {
        onboardingTrack: trackId,
        payment_method: paymentMethod,
    });
};
