import clsx from 'clsx';
import IconCycle from 'public/icons/small/icon-small-cycle.svg';

import $button from './button.module.scss';

interface ButtonProps {
    accessibilityLabel?: string;
    casing?: 'uppercase' | 'preserve';
    children: React.ReactNode;
    disabled?: boolean;
    id?: string;
    type?: 'button' | 'submit';
    loading?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClick: (...args: Array<any>) => void;
    sharpCorners?: 'both' | 'left' | 'right';
    testID?: string;
    variant: 'tangerine' | 'charcoal' | 'blueberry' | 'outlined' | 'ghost';
}

const Button = ({
    accessibilityLabel,
    casing = 'preserve',
    children,
    disabled,
    id,
    loading,
    onClick,
    sharpCorners,
    testID,
    variant,
    type = 'button',
}: ButtonProps) => {
    return (
        <div className={clsx($button.container)}>
            <button
                aria-label={accessibilityLabel}
                className={clsx($button.button, {
                    [$button.blueberry]: variant === 'blueberry',
                    [$button.charcoal]: variant === 'charcoal',
                    [$button.outlined]: variant === 'outlined',
                    [$button.ghost]: variant === 'ghost',
                    [$button.loading]: loading,
                    [$button.squaredBoth]: sharpCorners === 'both',
                    [$button.squaredLeft]: sharpCorners === 'left',
                    [$button.squaredRight]: sharpCorners === 'right',
                    [$button.tangerine]: variant === 'tangerine',
                    [$button.uppercase]: casing === 'uppercase',
                })}
                data-testid={testID}
                disabled={disabled || loading}
                id={id}
                onClick={onClick}
                type={type === 'submit' ? 'submit' : 'button'}
            >
                {loading && <IconCycle className={$button.spinner} />}
                {children}
            </button>
        </div>
    );
};

export { Button };
